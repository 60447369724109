@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Marcellus&family=Just+Another+Hand&family=Raleway&family=Roboto&family=Old+Standard+TT&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.app-admin,
.content-admin {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.app-admin {
  display: flex;
}

.hidden {
  display: none;
}

.right {
  float: right;
}

.is-sticky {
  position: fixed;
  top: 40px;
  left: 60%;
  z-index: 0;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  width: 390px;
}

.menu-item {
  transition: 0.3s ease;
  text-decoration: none;
  border-top: 4px solid #4B4B4B;
  border-bottom: 4px solid #4B4B4B;
  padding: 0px 0;
  margin: 0 20px;
}

/* CHATS STYLE  */

.list-cats {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "RaleWay";
  list-style-type: none;
}

.item-cats {
  text-align: center;
  display: inline-block;
  list-style: none;
  position: relative;
  border: solid 12px #fff;
  background: #fff;
  box-shadow: 0 0 15px 0px #555;
  transition: all 1s ease;
  -o-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  top: 50px;
}

.item-cats:hover {
  box-shadow: 0 0 15px 0px #e5b414;
  top: 20px;
  transform: scale(1.5);
  cursor: pointer;
}

.item-cats img {
  height: 250px;
  object-fit: cover;
  object-position: center;
}

.p-cats {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  font-family: "Dancing Script", sans-serif;
}

/* CHATONS STYLE  */
.wsk-li {
  width: 330px;
  margin-left: 10px;
}

.wsk-li:hover {
  cursor: pointer;
}

.wsk-cp-product {
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
  margin: 20px auto;
}
.wsk-cp-img {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  -khtml-transform: translate(-50%);
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-img img {
  width: 300px;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.wsk-cp-product:hover .wsk-cp-img {
  top: -40px;
}
.wsk-cp-product:hover .wsk-cp-img img {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.wsk-cp-text {
  padding-top: 65%;
}
.wsk-cp-text .category {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-text .category > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
}
.wsk-cp-text .category > span {
  padding: 12px 30px;
  border: 1px solid #c49a11;
  background: #e5b414;
  color: #fff;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 27px;
  transition: all 0.05s ease-in-out;
}
.wsk-cp-product:hover .wsk-cp-text .category > span {
  border-color: #83670b;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category {
  margin-top: 0px;
}
.wsk-cp-text .title-product {
  text-align: center;
}
.wsk-cp-text .title-product h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 5px auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.wsk-cp-text .description-prod p {
  margin: 0;
}
/* Truncate */
.wsk-cp-text .description-prod {
  text-align: center;
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
}
.card-footer {
  padding: 25px 0 5px;
  border-top: 1px solid #ddd;
}
.card-footer:after,
.card-footer:before {
  content: "";
  display: table;
}
.card-footer:after {
  clear: both;
}

.card-footer .wcf-left {
  float: left;
}

.card-footer .wcf-right {
  float: right;
}

.price {
  font-size: 18px;
  font-weight: bold;
}

a.buy-btn {
  display: block;
  color: #212121;
  text-align: center;
  font-size: 18px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #212121;
  transition: all 0.2s ease-in-out;
}
a.buy-btn:hover,
a.buy-btn:active,
a.buy-btn:focus {
  border-color: #ff9800;
  background: #ff9800;
  color: #fff;
  text-decoration: none;
}
.wsk-btn {
  display: inline-block;
  color: #212121;
  text-align: center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  border-color: #ff9800;
  background: #ff9800;
  padding: 12px 30px;
  border-radius: 27px;
  margin: 0 5px;
}
.wsk-btn:hover,
.wsk-btn:focus,
.wsk-btn:active {
  text-decoration: none;
  color: #fff;
}
.red {
  color: #f44336;
  font-size: 22px;
  display: inline-block;
  margin: 0 5px;
}

.admin-img {
  height: 200px;
  width: 250px;
  object-fit: cover;
  object-position: center;
}

/* FILTRE */

ol {
  list-style: none;
}

/* SHAKE ANIMATION */
.shake {
  animation: shake 1s infinite;
}
@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(3deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* TRUNCATE OVERFLOW */

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: "40px";
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.truncate-overflow::before {
  position: absolute;
  content: "...";
  inset-block-end: -2px; /* "bottom" */
  inset-inline-end: 5px; /* "right" */
}
.truncate-overflow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

@media screen and (max-width: 891px) {
  .wsk-cp-product {
    margin: 10px auto;
  }
  .wsk-cp-product .wsk-cp-img {
    top: 0px;
  }
  .wsk-cp-product .wsk-cp-img img {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  .wsk-cp-product .wsk-cp-text .category > span {
    border-color: #ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category {
    margin-top: 0px;
  }
  a.buy-btn {
    border-color: #ff9800;
    background: #ff9800;
    color: #fff;
  }
}

/* BLOG STYLE */

.date__box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 4px solid;
  font-weight: bold;
  padding: 5px 10px;
}
.date__day {
  font-size: 22px;
}

.blog-card {
  padding: 30px;
  position: relative;
  border: 1px solid #e0e0e0;
  background-color: #f6f6f6;
  margin-bottom: 30px;
}

.blog-card__info h5:hover {
  color: #ffb535;
}

.blog-card__info h5 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 0;
}
.card__background--layer:hover {
  opacity: 1;
}

.card__background--main:hover {
  transform: scale(1.2) rotate(5deg);
}

.date__box:hover {
  opacity: 1;
  transform: scale(1);
}

.date__box {
  opacity: 0;
  transform: scale(0.5);
  transition: 300ms ease-in-out;
}

.blog-card__background,
.card__background--layer {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blog-card__background {
  padding: 15px;
  z-index: 0;
  /* background: white; */
}
.card__background--wrapper {
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
  position: relative;
  overflow: hidden;
}
.card__background--main {
  height: 100%;
  position: relative;
  transition: 300ms ease-in-out;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.card__background--layer {
  z-index: 0;
  opacity: 0;
  background: rgba(#333, 0.9);
  transition: 300ms ease-in-out;
}
.blog-card__head {
  z-index: 1;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-card__info {
  z-index: 10;
  background: white;
  padding: 20px 15px;
  position: relative;
}

.blog-card__info h5 {
  transition: 300ms ease-in-out;
}

span.icon-link {
  color: #a3a3a3;
  transition: 200ms ease-in-out;
  margin-right: 15px;
  text-decoration: none;
}

span.icon-link i {
  color: #ffb535;
}

span.icon-link:hover {
  color: #ffb535;
  text-decoration: none;
}

.btn {
  background: white;
  color: #363738;
  font-weight: bold;
  outline: none;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding: 0;
  border: none;
}

.btn:focus {
  box-shadow: none;
}

.btn:hover {
  background: #ffb535;
  color: #fff;
}

.btn--with-icon {
  padding-right: 20px;
  text-decoration: none;
}

.btn--with-icon i {
  padding: 0px 30px 0px 15px;
  margin-right: 10px;
  height: 50px;
  line-height: 50px;
  vertical-align: bottom;
  color: white;
  background: #ffb535;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}

.btn--only-icon {
  width: 50px;
}

.editor-class-chat {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 200px;
  font-family: "RaleWay";
  font-size: 18px;
}

.editor-class-actualite {
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 500px;
  font-family: "RaleWay";
  font-size: 18px;
}

/* TIMELINE */

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 30px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #eab815;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.img-fluid {
  height: 50px;
  width: 80px;
}

.latest-article {
  display: flex;
}

.latest-article-text {
  margin-left: 30px;
}

.latest-article-text h3 {
  margin: 0;
  color: #0a0a0a;
}

.latest-article-text p {
  margin-top: 10px;
  color: #a3a3a3;
}

.latest-article-link {
  text-decoration: none;
}

.latest-article-text h3:hover {
  color: #eab815;
}

.latest-actu h4 {
  text-align: center;
  font-size: 1.25rem;
  font-family: "RaleWay";
  margin-bottom: 50px;
}

/* Latest Actu */

.blog_section {
  margin-top: 50px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.blog_section .blog_content {
  margin-top: 5rem;
}
.blog_section .blog_content .blog_item {
  margin-bottom: 30px;
  box-shadow: 0 0 11px 0 rgba(6, 22, 58, 0.14);
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}
.blog_section .blog_content .blog_item:hover .blog_image img {
  transform: scale(1.1);
}
.blog_section .blog_content .blog_item .blog_image {
  overflow: hidden;
  padding: 0;
}
.blog_section .blog_content .blog_item .blog_image img {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}
.blog_section .blog_content .blog_item .blog_image span > * {
  position: absolute;
  z-index: 2;
  color: #fff;
  font-size: 18px;
  width: 38px;
  height: 45px;
  padding-top: 7px;
  text-align: center;
  right: 20px;
  top: 0;
  transform: rotate(30deg);
  /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 79%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 79%, 0 100%); 
  background-color: #ff5e14;*/
}

.blog_section .blog_content .blog_item .blog_details {
  padding: 0px 20px 30px 20px;
}
.blog_title {
  border-top: none !important;
}
.blog_section .blog_content .blog_item .blog_details .blog_title h5 a {
  color: #020d26;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 32px;
  font-weight: 400;
  transition: all 0.3s;
  text-decoration: none;
}
.blog_section .blog_content .blog_item .blog_details .blog_title h5 a:hover {
  color: #ff5e14;
}
.blog_section .blog_content .blog_item .blog_details ul {
  padding: 0 3px 10px 0;
  margin: 0;
}
.blog_section .blog_content .blog_item .blog_details ul li {
  display: inline-block;
  padding-right: 15px;
  position: relative;
  color: #7f7f7f;
  font-size: 14px;
}
.blog_section .blog_content .blog_item .blog_details ul li i {
  padding-right: 7px;
}
.blog_section .blog_content .blog_item .blog_details div {
  border-top: 1px solid #e5e5e5;
  margin-top: 4px;
  padding: 0px 0 4px;
}
.blog_section .blog_content .blog_item .blog_details a {
  font-size: 16px;
  display: inline-block;
  color: #ff5e14;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}
.blog_section .blog_content .blog_item .blog_details a:hover {
  color: #020d26;
}
.blog_section .blog_content .blog_item .blog_details a i {
  vertical-align: middle;
  font-size: 20px;
}
.blog_section .blog_content .owl-nav {
  display: block;
}
.blog_section .blog_content .owl-nav .owl-prev {
  position: absolute;
  left: -27px;
  top: 33%;
  border: 5px solid #fff;
  text-align: center;
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: 0;
  background: #ff5e14;
  transition: all 0.3s;
  color: #fff;
}
.blog_section .blog_content .owl-nav .owl-prev span {
  font-size: 25px;
  margin-top: -6px;
  display: inline-block;
}
.blog_section .blog_content .owl-nav .owl-prev:hover {
  background: #fff;
  border-color: #ff5e14;
  color: #ff5e14;
}
.blog_section .blog_content .owl-nav .owl-next {
  position: absolute;
  right: -27px;
  top: 33%;
  border: 5px solid #fff;
  text-align: center;
  z-index: 5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: 0;
  background: #ff5e14;
  color: #fff;
  transition: all 0.3s;
}
.blog_section .blog_content .owl-nav .owl-next span {
  font-size: 25px;
  margin-top: -6px;
  display: inline-block;
}
.blog_section .blog_content .owl-nav .owl-next:hover {
  background: #fff;
  border-color: #ff5e14;
  color: #ff5e14;
}

.mySwiper {
  max-width: 1200px;
  margin: auto;
}

.mySwiper-Chat .swiper,
.mySwiper-Chat2 .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper-Chat .swiper-slide,
.mySwiper-Chat2 .swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper-Chat2 .swiper-wrapper {
  align-items: center;
}

.mySwiper-Chat2 {
  height: 80%;
  width: 100%;
  padding-bottom: 10px;
}

.mySwiper-Chat2 {
  height: 80%;
  box-sizing: border-box;
  padding: 10px !important;
  margin-bottom: 10px;
}

.mySwiper-Chat2:hover{
  cursor: pointer;
}

.mySwiper-Chat .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper-Chat .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper-Chat .swiper-slide img,
.mySwiper-Chat2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 890px) {
  .blog_section .owl-nav .owl-prev {
    left: -17px !important;
  }
  .blog_section .owl-nav .owl-next {
    right: -17px !important;
  }
}

/* Version MOBILE */
@media (max-width: 991px) {
  #header-logo {
    display: none;
  }

  .menu-item {
    width: 100%;
    transition: 0.3s ease;
    text-decoration: none;
    border-top: none;
    border-bottom: none;
    padding-bottom: 10px;
    padding-top: 20px;
    margin: 0;
  }

  #menu-icon {
    font-size: 40px !important;
  }

  #menu-appbar .MuiPaper-root {
    top: 0px !important;
    width: 80%;
    left: 20% !important;
    height: 100%;
    border-radius: 0px;
  }
  .MuiToggleButton-root {
    font-size: 10px !important;
  }

  .posts {
    padding-left: 0px;
  }

  .wsk-cp-product {
    margin-bottom: 0px;
  }

  .mySwiper {
    max-width: 400px;
    margin: auto;
  }
}
